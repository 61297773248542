import React from "react";
import { Outlet } from "react-router-dom";

function ArticlesOuter() {
  return (
    <div style={{ padding: 20 }}>
      <h2>Blog2</h2>
      <Outlet />
    </div>
  );
}

export default ArticlesOuter;
