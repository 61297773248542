import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import SEO from "./SEO";
// import OmniglotBackgroundImage from "../assets/images/engines/Omniglot_bg.jpg";
// import Bullying10KBackgroundImage from "../assets/images/engines/Bullying10K_bg.jpg";
// import streamBackgroundImage from "../assets/images/engines/stream_bg.jpg";
import { Fade, Slide } from "react-awesome-reveal";

function Datasets({stream = false}) {
    const {t, i18n} = useTranslation(['home', 'html_title', 'common']);
    const isChinese = i18n.language === 'zh';
    const heroVideoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    return (
        <div id={"engines"} className={"flex justify-center items-center flex-col pt-12 px-5"}>
            <div className={" max-w-4xl mx-auto"}>
                <div className={`text-5xl md:text-6xl bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-br from-[#ff6995] to-[#73cdff] font-extrabold text-center leading-normal ${isChinese ? 'tracking-[.1em]' : 'tracking-[.02em]'}`}>{t('dataset_section_title')}</div>
                {/*<div className={"text-neutral-300 text-xl font-light font-sans leading-relaxed mt-10 text-center"}>{t('dataset_section_intro')}</div>*/}
                {/*Omniglot*/}
                <div className={"mt-20"}>
                    <div className={"text-4xl md:text-5xl bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-green-400 to-blue-700 font-extrabold leading-relaxed md:leading-loose"}>{t('dataset_Omniglot_title')}</div>
                    <div className={"text-neutral-300 text-2xl font-light font-sans leading-relaxed mt-3 mb-8"}>{t('dataset_Omniglot_subtitle')}</div>
                    <Fade delay={100} triggerOnce>
                        <div
                            className={"border border-neutral-500 bg-cover bg-black/70 transition-all duration-300 ease-in-out bg-blend-multiply bg-bottom bg-no-repeat min-h-[250px] w-full flex justify-around items-center flex-wrap px-6 py-12 rounded-3xl"}
                        >
                            <div className={"lg:max-w-[50%]"}>
                                <div className={"text-neutral-300 text-xl font-light font-sans leading-relaxed mt-2 mb-2"}>{t('dataset_Omniglot_intro')}</div>
                                <a className={"inline-block font-sans mt-6 py-2 px-5 bg-gradient-to-r from-green-700 to-blue-700 rounded-full text-neutral-200 hover:text-neutral-50 font-normal text-base tracking-[.05em]"}
                                   href={"http://brain-cog.network/dataset/N-Omniglot/"}>{t('dataset_read_more_button')} {t('dataset_Omniglot_title')}</a>
                            </div>
                            <Fade delay={500} triggerOnce>
                                <img src={process.env.PUBLIC_URL + `/images/dataset/videos/n-omniglot.gif`} loading="lazy"
                                     alt={"Omniglot"}
                                     className={"transition-all duration-500 ease-in-out rounded max-h-[150px] mt-12 lg:mt-0"}
                                />
                            </Fade>
                        </div>
                    </Fade>
                </div>
                {/*Bullying10K*/}
                <div className={"mt-24"}>
                    <div className={"text-4xl md:text-5xl bg-gradient-to-t from-[#df74b1] to-[#7e6df1] inline-block text-transparent bg-clip-text font-extrabold leading-relaxed md:leading-loose"}>{t('dataset_Bullying10K_title')}</div>
                    <div className={"text-neutral-300 text-2xl font-light font-sans leading-relaxed mt-3 mb-8"}>{t('dataset_Bullying10K_subtitle')}</div>
                    <Fade delay={100} triggerOnce>
                        <div
                            className={"border border-neutral-500 bg-cover bg-black/70 transition-all duration-300 ease-in-out bg-blend-multiply bg-bottom bg-no-repeat min-h-[300px] w-full flex justify-around items-center flex-wrap px-6 py-12 rounded-3xl"}
                        >
                            <div className={" lg:max-w-[50%]"}>
                                <div className={"text-neutral-300 text-xl font-light font-sans leading-relaxed mt-2 mb-2"}>{t('dataset_Bullying10K_intro')}</div>
                                <a className={"inline-block font-sans mt-6 py-2 px-5 bg-gradient-to-r from-[#df74b1] to-[#7e6df1] rounded-full text-neutral-200 hover:text-neutral-50 transition-all font-normal text-base tracking-[.05em]"}
                                   href={"http://brain-cog.network/dataset/Bullying10k/"}>{t('dataset_read_more_button')} {t('dataset_Bullying10K_title')}</a>
                            </div>
                            <Fade delay={500} triggerOnce>
                                <video src={process.env.PUBLIC_URL + `/images/dataset/videos/kicking.mp4`} autoPlay loop muted controls
                                       className={"transition-all duration-500 ease-in-out rounded max-h-[200px] mt-12 lg:mt-0"}
                                />
                            </Fade>
                        </div>
                    </Fade>
                </div>
                {/*stream*/}
                {stream &&
                    <div className={"mt-24"}>
                        <div className={"text-4xl md:text-5xl bg-gradient-to-t from-[#01594d] to-[#8dffed] inline-block text-transparent bg-clip-text font-extrabold leading-relaxed md:leading-loose"}>{t('dataset_stream_title')}</div>
                        <div className={"text-neutral-300 text-2xl font-light font-sans leading-relaxed mt-3 mb-8"}>{t('dataset_stream_subtitle')}</div>
                        <Fade delay={100} triggerOnce>
                            <div
                                className={"border border-neutral-500 bg-cover bg-black/70 transition-all duration-300 ease-in-out bg-blend-multiply bg-center bg-no-repeat min-h-[350px] w-full flex justify-around items-center flex-wrap px-6 py-12 rounded-3xl"}
                            >
                                <div className={"lg:ml-8 lg:max-w-[50%]"}>
                                    <div className={"text-neutral-300 text-xl font-light font-sans leading-relaxed mt-2 mb-2 lg:max-w-[500px]"}>{t('dataset_stream_intro')}</div>
                                    <a className={"inline-block font-sans mt-6 py-2 px-5 bg-gradient-to-r from-teal-700 to-teal-500 rounded-full text-neutral-200 hover:text-neutral-50 transition-all font-normal text-base tracking-[.05em]"}
                                       href={isChinese ? "http://safe-and-ethical.ai/stream" : "http://safe-and-ethical.ai/stream/en"}>{t('dataset_read_more_button')} {t('dataset_stream_title')}</a>
                                </div>
                                <Fade delay={500} triggerOnce>
                                    <img src={process.env.PUBLIC_URL + `/images/dataset/videos/智善如流1.png`} loading="lazy"
                                         alt={"Omniglot"}
                                         className={"transition-all duration-500 ease-in-out rounded max-h-[170px] mt-12 lg:mt-0 sm:mx-24"}
                                    />
                                </Fade>
                            </div>
                        </Fade>
                    </div>
                }
            </div>
        </div>
    )
}

export default Datasets