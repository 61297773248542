import '../styles/Common.css';
import '../styles/About.css';
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import SEO from "./SEO";
import heroBackgroundImage from '../assets/images/hero_background/about_hero_bg_original4.jpg';


const People = () => {
    const {t, i18n} = useTranslation(['about', 'common', 'title'])
    const isChinese = i18n.language === 'zh';
    const people_cn = [
        {
            name: '曾毅',
            role: '北京人工智能安全与治理实验室主任',
            image: 'yi_zeng.jpg',
            titles: [
                '中国科学院自动化研究所人工智能伦理与治理中心主任',
                '联合国人工智能高层顾问机构(UN AI Advisory Body)专家',
                '北京市人工智能战略咨询专家委员会委员',
                '《时代周刊(TIME)》人工智能领域最具影响力100人(TIME 100/AI)',
            ]
        },
        {
            name: '魏凯',
            role: '北京人工智能安全与治理实验室副主任',
            image: 'kai_wei.jpg',
            titles: [
                '中国信息通信研究院人工智能研究所所长',
                '中国人工智能产业发展联盟（AIIA）总体组组长',
                '国际电信联盟SG16国内对口组组长',
            ]
        },
        {
            name: '苏航',
            role: '北京人工智能安全与治理实验室合作研究员',
            image: 'hang_su.jpg',
            titles: [
                '清华大学 副研究员',
                '清华大学人工智能研究院 院长助理',
                '中组部万人计划“青年拔尖人才”',
                'IEEE P7018 生成式人工智能安全性和可信性标准 工作组主席',
                '国际人工智能顶级期刊IEEE TPAMI 和Artificial Intelligence 编委',
            ]
        },
        {
            name: '杨耀东',
            role: '北京人工智能安全与治理实验室合作研究员',
            image: 'yaodong_yang.jpg',
            titles: [
                '北京大学人工智能研究院研究员（博雅学者）',
                '北京大学人工智能安全与治理中心执行主任',
                '北京智源研究院大模型安全项目主任',
                '国家人社部高层次人才计划、国家优青（海外）',
            ]
        }
    ]
    const people_en = [
        {
            name: 'Yi Zeng',
            role: 'Director of Beijing Institute of AI Safety and Governance',
            image: 'yi_zeng.jpg',
            titles: [
                'Director of International Research Center for AI Ethics and Governance, Institute of Automation, Chinese Academy of Sciences',
                'Expert, UN AI Advisory Body',
                'Member, Beijing AI Strategy Advisory Expert Committee',
                'Named one of TIME 100 Most Influential People in AI by TIME Magazine',
            ]
        },
        {
            name: 'Kai Wei',
            role: 'Deputy Director of Beijing Institute of AI Safety and Governance',
            image: 'kai_wei.jpg',
            titles: [
                'Director, AI Research Institute, China Academy of Information and Communications Technology (CAICT)',
                'Head of General Group, China AI Industry Development Alliance (AIIA)',
                'Chair, Domestic Correspondence Group for ITU-T SG16',
            ]
        },
        {
            name: 'Hang Su',
            role: 'Collaboration Fellow of Beijing Institute of AI Safety and Governance',
            image: 'hang_su.jpg',
            titles: [
                'Associate Researcher, Tsinghua University',
                'Assistant Director, Institute for Artificial Intelligence, Tsinghua University',
                '"National High-Level Talents Special Support Plan"',
                'Chair, IEEE P7018 Standard Working Group on Safety and Trustworthiness of Generative AI',
                'Editorial Board Member of IEEE TPAMI and Artificial Intelligence',
            ]
        },
        {
            name: 'Yaodong Yang',
            role: 'Collaboration Fellow of Beijing Institute of AI Safety and Governance',
            image: 'yaodong_yang.jpg',
            titles: [
                'Researcher (Boya Scholar), Institute for Artificial Intelligence, Peking University',
                'Executive Director, Center for AI Safety and Governance, Peking University',
                'Director, LLM Safety Centre, Beijing Academy of Artificial Intelligence (BAAI)',
                'Selected for the National High-level Talents Program and National Youth Overseas High-level Talent Introduction Program',
            ]
        }
    ];

    return (
        <div className={"bg-white"}>
            <SEO title={t('about', {ns: 'html_title'})}></SEO>
            <div className={"bg-cover bg-white bg-blend-hard-light bg-bottom bg-no-repeat min-h-[350px] sm:min-h-[400px] w-full flex flex-col justify-start items-center p-6"}
                 style={{backgroundImage: `url(${heroBackgroundImage})`}}
            >
                <div className={"flex flex-col justify-center items-start max-w-[900px] mt-36 mb-16"}>
                    <div className={`font-bold tracking-[.02em] text-white leading-normal ${isChinese ? 'text-4xl sm:text-[3.5rem] sm:tracking-[.05em]' : 'text-4xl sm:text-[3rem] sm:tracking-[.02em]'}`}>
                        {isChinese ? '实验室结构' : 'Institute Structure'}
                    </div>
                </div>
            </div>
            <div className={"content-container py-24"}>
                <div className={"flex flex-col justify-center items-center max-w-6xl mx-auto px-6 sm:px-12 pb-36 rounded-3xl bg-cover bg-white bg-blend-multiply bg-center-top bg-no-repeat"}>
                    {isChinese ? people_cn.map((person, index) => (
                        <div key={index} className="border-b-4 border-[#c00000] w-full md:w-[600px] lg:w-[800px] mb-16 px-8 py-12 flex flex-wrap md:flex-row items-center bg-gray-50 shadow-lg">
                            <img className="mb-4 w-32 h-32 object-cover rounded-full border-2 border-gray-300 md:mr-12"
                                 src={process.env.PUBLIC_URL + `/images/people/${person.image}`} alt={person.name}
                                 style={{ objectPosition: 'center top' }}
                            />
                            <div className="flex flex-col">
                                <p className="mb-5 text-[#c00000] font-semibold">{person.role}</p>
                                <h3 className="text-3xl font-medium text-gray-800 mb-10">{person.name}</h3>
                                <ul className="list-disc list-inside text-gray-600">
                                    {person.titles.map((title, idx) => (
                                        <li key={idx}>{title}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )):
                   people_en.map((person, index) => (
                        <div key={index} className="border-b-4 border-[#c00000] w-full md:w-[600px] lg:w-[800px] mb-16 px-8 py-12 flex flex-wrap md:flex-row justify-between items-center bg-gray-50 shadow-lg">
                            <img className="mb-4 w-32 h-32 object-cover rounded-full border-2 border-gray-300 md:mr-12"
                                 src={process.env.PUBLIC_URL + `/images/people/${person.image}`} alt={person.name}
                                 style={{ objectPosition: 'center top' }}
                            />
                            <div className="flex flex-col lg:max-w-[75%]">
                                <p className="mb-5 text-[#c00000] font-semibold">{person.role}</p>
                                <h3 className="text-3xl font-medium text-gray-800 mb-10">{person.name}</h3>
                                <ul className="list-disc list-inside text-gray-600">
                                    {person.titles.map((title, idx) => (
                                        <li key={idx}>{title}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))
                    }
                </div>
            </div>
        </div>
    );
};

export default People;