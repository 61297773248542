import '../styles/Footer.css';
import React from 'react';
import {useTranslation, Trans} from "react-i18next";

const Bottombar = () => {
    const current_year = new Date().getFullYear();
    const {t, i18n} = useTranslation(['footer', 'common'])

    return (
        <div className={"footer-banner-container"}>
            <div className={"footer-banner"}>
                <img src={process.env.PUBLIC_URL + `/logo/red_logo_without_bg_with_text.png`} className={"max-w-[120px] bg-white rounded-full p-[2px]"} alt={"logo"}/>

{/*                <div className={"footer-column"}>
                    <div className={"footer-title"}>{t('contact_us')}</div>
                    <div className={"footer-item"}>TEL：+86 15001313499</div>
                    <div className={"footer-item"}>E-Mail：<a href={"mailto:contact@long-term-ai.cn"} target={"_blank"} rel="noreferrer">contact@long-term-ai.cn</a></div>
                    <div className={"footer-item"}>{t('address')}</div>
                </div>*/}
                <div className={""}>
                    <div className={"footer-title"}>{t('copyright')}</div>
                    <div className={"footer-item"}>© {current_year === 2024 ? '2024' : `2024 - ${current_year}`} <a href={"/"} rel="noreferrer">{t('biaisg')}</a></div>
                    {/*<div className={"footer-item"}></div>*/}
                    {/*<div className={"footer-item"}><a href={"http://www.braincog.ai/"} target={"_blank"} rel="noreferrer">{t('bici')},</a></div>*/}
                    {/*<div className={"footer-item"}><a href={"https://ai-ethics-and-governance.institute/"} target={"_blank"} rel="noreferrer">{t('aieg')},</a></div>*/}
                    {/*<div className={"footer-item"}><a href={"http://www.ia.cas.cn/"} target={"_blank"} rel="noreferrer">{t('iaac')}.</a></div>*/}
                    {/*<div className={"footer-item"}><a href={"https://long-term-ai.center/"} rel="noreferrer">{t('clai')}</a>.</div>*/}
                    {/*<div className={"footer-item"}>{t('szsy')}.</div>*/}
                    {/*<div className={"footer-item"}>中国科学院哲学研究所.</div>*/}
                    <div className={"footer-item"}>{t('reserve_all')}</div>
                </div>
{/*                <div className={"footer-column"}>
                    <div className={"footer-title"}>{t('wechat')}</div>
                    <div className={"footer-item"}><img src={process.env.PUBLIC_URL + `/images/QRCode.png`} alt={"QRCode"}/></div>
                </div>*/}
            </div>
        </div>
    )
}

export default Bottombar
