import '../styles/Common.css';
import '../styles/About.css';
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import SEO from "./SEO";
import heroBackgroundImage from '../assets/images/hero_background/about_hero_bg_original4.jpg';


const FoundationalModel = () => {
    const {t, i18n} = useTranslation(['about', 'common', 'title'])
    const isChinese = i18n.language === 'zh';
    return (
        <div className={"bg-white"}>
            <SEO title={t('about', {ns: 'html_title'})}></SEO>
            <div className={"bg-cover bg-white bg-blend-hard-light bg-bottom bg-no-repeat min-h-[350px] sm:min-h-[400px] w-full flex flex-col justify-start items-center p-6"}
                 style={{backgroundImage: `url(${heroBackgroundImage})`}}
            >
                <div className={"flex flex-col justify-center items-start max-w-[900px] mt-36 mb-16 md:mr-48"}>
                    <div className={`font-bold tracking-[.02em] text-white/90 leading-normal ${isChinese ? 'text-4xl sm:text-[3.5rem] sm:tracking-[.05em]' : 'text-4xl sm:text-[3rem] sm:tracking-[.02em]'}`}>
                        {isChinese ? '安全人工智能基础模型' : 'Safe AI Foundational Model'}
                    </div>
                </div>
            </div>
            <div className={"content-container py-24 px-5"}>
                <div className={"flex flex-col justify-center items-center max-w-6xl mx-auto px-6 sm:px-12 pb-36 rounded-3xl bg-cover bg-white bg-blend-multiply bg-center-top bg-no-repeat"}>
                    <div className={""}>
                        {/*<p className="text-neutral-600 text-xl font-light font-sans leading-loose">{t('p1')}</p>*/}
                        {isChinese ?
                            <div className="text-neutral-600 text-xl font-light font-sans leading-loose">
                                <ul className="list-disc">
                                    <li>安全作为第一性原理 (不可违背、无法删除)</li>
                                    <li>合乎伦理道德</li>
                                    <li>具备高度，可验证的内生安全</li>
                                    <li>对外部攻击具有强大对抗能力</li>
                                </ul>
                            </div>
                            :
                            <div className="text-neutral-600 text-xl font-light font-sans leading-loose">
                                <ul className="list-disc">
                                    <li>Safety as a First Principle (cannot be violated or deleted)</li>
                                    <li>Compliance with Ethical Standards</li>
                                    <li>High, Verifiable Intrinsic Safety</li>
                                    <li>Strong Resilience Against External Attacks</li>
                                </ul>
                            </div>

                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FoundationalModel;