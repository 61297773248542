import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

function ScrollToTopOnMount() {
    const {pathname, hash} = useLocation();
    // console.log('pathname: ', pathname, 'hash: ', hash);
    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({behavior: 'smooth'});
            }
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            // console.log('scrolling to top');
        }
    }, [pathname, hash]);

    return null;
}

export default ScrollToTopOnMount;
