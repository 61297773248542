import '../styles/Common.css';
import '../styles/About.css';
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import SEO from "./SEO";
import heroBackgroundImage from '../assets/images/hero_background/about_hero_bg_original4.jpg';
import React from "react";
import {useParams} from "react-router-dom";


const About = () => {
    const {t, i18n} = useTranslation(['about', 'common', 'title', 'home'])
    const isChinese = i18n.language === 'zh';

    return (
        <div className={"bg-white"}>
            <SEO title={t('about', {ns: 'html_title'})}></SEO>
{/*            <div className={"header-container"}>
                <div className={"header"}>
                    <div className={"header-title-1"}>{t('about')} {t('project_name_full', {ns: 'common'})}</div>
                    <div className={"header-title-2"}>{t('title')}</div>
                </div>
            </div>*/}
            <div className={"bg-cover bg-white bg-blend-hard-light bg-bottom bg-no-repeat min-h-[350px] sm:min-h-[400px] w-full flex flex-col justify-start items-center p-6"}
                 style={{backgroundImage: `url(${heroBackgroundImage})`}}
            >
                <div className={"flex flex-col justify-center items-start max-w-[900px] mt-36 mb-16 md:mr-48"}>
                    <div className={`font-bold tracking-[.02em] text-white/90 leading-normal ${isChinese ? 'text-4xl sm:text-[3.5rem] sm:tracking-[.05em]' : 'text-4xl sm:text-[3rem] sm:tracking-[.03em]'}`}>
                        {t('about')}<br/>
                        {t('hero_heading_1')}
                    </div>
                </div>
            </div>
            <div className={"content-container py-24"}>
                <div className={"flex flex-wrap justify-evenly items-start max-w-8xl mx-auto px-6 sm:px-12 pb-36 rounded-3xl bg-cover bg-white bg-blend-multiply bg-center-top bg-no-repeat"}>
                    <div id={"logo_animation"} className={"flex justify-center items-center flex-col pt-0 pb-6 bg-white"}>
                        <video
                            src={process.env.PUBLIC_URL + `/logo/red_logo_min.mp4`}
                            className={"w-full max-w-[400px] bg-white rounded-full p-[2px]"}
                            loop
                            muted
                            autoPlay
                        />
                    </div>
                    <div className={"lg:max-w-[60%]"}>
                        {isChinese ?
                            (
                                <div className={"text-neutral-600 text-xl font-light font-sans leading-loose"}>
                                    <p className="">
                                        北京人工智能安全与治理实验室是面向人工智能安全发展与治理的新型研发机构。实验室致力于构建系统化的安全与治理体系，为人工智能的创新和应用提供坚实的安全保障，引领人工智能安全与治理的新趋势。实验室在北京市多个委办局的指导与支持下建立，与中国科学院自动化研究所人工智能伦理与治理中心、中国信通院人工智能研究所共建，并得到来自清华大学、北京大学等高校和科研机构研究团队的合作支持。实验室坐落于北京市中关村科技园门头沟园京西智谷，并得到门头沟区政府的大力支持。
                                    </p>
                                    <p className="mt-4">
                                        实验室与共建单位发布了“人工智能安全与治理公共服务平台”，平台可在人工智能伦理安全政策、安全风险监测、伦理安全评测方面提供服务。近期，实验室正与共建单位、合作机构共同打造人工智能伦理与安全评测体系、构建安全人工智能基础模型。实验室也将利用北京市丰富的学术资源和产业优势，推动跨学科合作，不断探索新的研究路径，解决人工智能伦理与安全领域中的紧迫迫切，布局长远议题。
                                    </p>
                                    <p className="mt-24">
                                        联系我们：<a href={"mailto:contact@beijing.ai-safety-and-governance.institute"} target={"_blank"} rel="noreferrer">contact@beijing.ai-safety-and-governance.institute</a>
                                    </p>
                                </div>
                            ) : (
                                <div className="text-neutral-600 text-xl font-light font-sans leading-loose">
                                    <p className="">
                                        The Beijing Institute of AI Safety and Governance (Beijing-AISI) is a new type of R&D institution focused on the safe development and governance of artificial intelligence. The Institute is dedicated to building a systematic safety and governance
                                        framework to provide a solid safety foundation for AI innovation and application, leading new trends in AI safety and governance. The Institute was established under the guidance and support of several Beijing municipal commissions and offices, co-founded
                                        with the International Research Center for AI Ethics and Governance, Institute of Automation, Chinese Academy of Sciences, and the AI Institute of the China Academy of Information and Communications Technology. It also receives collaborative support from
                                        research teams at
                                        universities and institutions such as Tsinghua University and Peking University. The Institute is located in Jingxi Intelligence Valley of the Mentougou Park, Zhongguancun Science Park, Beijing, with strong support from the Mentougou District Government.
                                    </p>
                                    <p className="mt-4">
                                        The Institute, together with its co-founding units, has launched the "AI Safety and Governance Platform" which provides services in AI ethics and safety policies, safety risk monitoring, and ethics and safety assessments. Recently, the
                                        institute has been working with its co-construction partners and cooperative institutions to develop an AI Ethics and Safety Assessment System and build a Safe AI Foundational Model. The Institute will also leverage Beijing's rich academic resources and
                                        industrial
                                        strengths to promote interdisciplinary collaboration, continuously explore new research paths, address urgent issues in the field of AI ethics and safety, and plan for long-term challenges.
                                    </p>
                                    <p className="mt-24">
                                        Contact us：<a href={"mailto:contact@beijing.ai-safety-and-governance.institute"} target={"_blank"} rel="noreferrer">contact@beijing.ai-safety-and-governance.institute</a>
                                    </p>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
