import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import SEO from "./SEO";
import braincogBackgroundImage from "../assets/images/engines/blue.svg";
import bornBackgroundImage from "../assets/images/engines/brown.svg";
import brainknowBackgroundImage from "../assets/images/engines/pink.svg";
import greenBackgroundImage from "../assets/images/engines/green.jpg";
import {Fade, Slide} from "react-awesome-reveal";

function BrainCog_Born({brainknow = false}) {
    const {t, i18n} = useTranslation(['home', 'html_title', 'common']);
    const isChinese = i18n.language === 'zh';

    return (
        <div id={"engines"} className={"flex justify-center items-center flex-col pt-24 px-5 bg-white"}>
            <div className={"w-[100%] sm:w-[75%] xl:w-[75%] max-w-7xl mx-auto"}>
                <div className={`text-4xl md:text-5xl bg-clip-text text-transparent bg-[#c00000] sm:bg-gradient-to-b from-[#c00000] to-[#ffe6e6] font-extrabold text-center leading-relaxed md:leading-loose ${isChinese ? 'tracking-[.03em]' : 'tracking-[.02em]'}`}>{t('engine_section_title')}</div>
                <div className={"text-neutral-700 text-xl font-light font-sans leading-relaxed mt-10 text-center"}>{t('engine_section_intro')}</div>
                {/*braincog*/}
                <div className={"mt-10"}>
                    {/*<div className={`bg-clip-text text-transparent bg-gradient-to-b from-blue-400/100 to-blue-400/30 font-extrabold leading-relaxed md:leading-relaxed ${isChinese ? 'text-3xl md:text-4xl' : 'text-4xl md:text-5xl'}`}>{t('engine_braincog_title')}</div>*/}
                    {/*<div className={"text-neutral-300 text-2xl font-light font-sans leading-relaxed mt-3 mb-8"}>{t('engine_braincog_subtitle')}</div>*/}
                    <a
                        className={"bg-cover bg-white hover:bg-black/5 transition-all duration-300 ease-in-out bg-blend-multiply bg-center bg-no-repeat min-h-[225px] w-full flex justify-center items-center flex-wrap p-6"}
                        style={{backgroundImage: `url(${braincogBackgroundImage})`}}
                        href={"http://linking-ai-policies.org"}
                    >
                        <div className={"lg:ml-8"}>
                            <div className={`bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-white/100 to-blue-400/50 font-extrabold leading-relaxed md:leading-snug text-center ${isChinese ? 'text-3xl md:text-5xl' : 'text-4xl md:text-5xl'}`}>{t('engine_braincog_intro')}</div>
                            {/*<div className={"text-xl text-slate-100 font-normal leading-normal mt-10"}>{t('engine_brainknow_intro_suffix')}</div>*/}
                        </div>
                    </a>
                </div>
                {/*born*/}
                <div className={"mt-10"}>
                    {/*<div className={`bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-lime-500/100 to-lime-500/30 font-extrabold leading-relaxed md:leading-relaxed ${isChinese ? 'text-3xl md:text-4xl' : 'text-4xl md:text-5xl'}`}>{t('engine_born_title')}</div>*/}
                    {/*<div className={"text-neutral-300 text-2xl font-light font-sans leading-relaxed mt-3 mb-8"}>{t('engine_born_subtitle')}</div>*/}
                    <a
                        className={"bg-cover bg-white hover:bg-black/5 transition-all duration-300 ease-in-out bg-blend-multiply bg-center bg-no-repeat min-h-[225px] w-full flex justify-center items-center flex-wrap p-6"}
                        style={{backgroundImage: `url(${bornBackgroundImage})`}}
                        href={`${isChinese ? "http://ai-safety-and-governance.online/cn" : "http://ai-safety-and-governance.online"}`}
                    >
                        <div className={"lg:ml-8"}>
                            <div className={`bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-yellow-600 to-yellow-950 font-extrabold leading-normal md:leading-snug text-center ${isChinese ? 'text-3xl md:text-5xl' : 'text-4xl md:text-5xl'}`}>{t('engine_born_intro')}</div>
                            {/*<div className={"text-xl text-slate-100 font-normal leading-normal mt-10"}>{t('engine_brainknow_intro_suffix')}</div>*/}
                        </div>
                    </a>
                </div>
                {/*brainknow*/}
                {brainknow &&
                    <div className={"mt-10"}>
                        {/*<div className={`bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-purple-200 to-purple-900 font-extrabold leading-relaxed md:leading-relaxed ${isChinese ? 'text-3xl md:text-4xl' : 'text-4xl md:text-5xl'}`}>{t('engine_brainknow_title')}</div>*/}
                        {/*<div className={"text-neutral-300 text-2xl font-light font-sans leading-relaxed mt-3 mb-8"}>{t('engine_brainknow_subtitle')}</div>*/}
                        <a
                            className={"bg-cover bg-white hover:bg-black/5 transition-all duration-300 ease-in-out bg-blend-multiply bg-center bg-no-repeat min-h-[225px] w-full flex justify-center items-center flex-wrap p-6"}
                            style={{backgroundImage: `url(${brainknowBackgroundImage})`}}
                            href={`${isChinese ? "http://ai-safety-and-governance.online/ai-risks-and-safety-observatory/cn" : "http://ai-safety-and-governance.online/ai-risks-and-safety-observatory"}`}
                        >
                            <div className={"lg:ml-8"}>
                                <div
                                    className={`bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-purple-300 to-purple-900 font-extrabold leading-relaxed md:leading-snug text-center ${isChinese ? 'text-3xl md:text-5xl' : 'text-4xl md:text-5xl'}`}>{t('engine_brainknow_intro')}</div>
                                {/*<div className={"text-xl text-slate-100 font-normal leading-normal mt-10"}>{t('engine_brainknow_intro_suffix')}</div>*/}
                            </div>
                        </a>
                    </div>
                }
                {/*ceo*/}
                <div className={"mt-10"}>
                    {/*<div className={`bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-purple-200 to-purple-900 font-extrabold leading-relaxed md:leading-relaxed ${isChinese ? 'text-3xl md:text-4xl' : 'text-4xl md:text-5xl'}`}>{t('engine_brainknow_title')}</div>*/}
                    {/*<div className={"text-neutral-300 text-2xl font-light font-sans leading-relaxed mt-3 mb-8"}>{t('engine_brainknow_subtitle')}</div>*/}
                    <a
                        className={"bg-cover bg-white hover:bg-black/5 transition-all duration-300 ease-in-out bg-blend-multiply bg-center bg-no-repeat min-h-[225px] w-full flex justify-center items-center flex-wrap p-6"}
                        style={{backgroundImage: `url(${greenBackgroundImage})`}}
                        href={`/large-ai-models-safety-and-ethics-examiner`}
                    >
                        <div className={"lg:ml-8"}>
                            <div className={`bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-green-500 to-green-900 font-extrabold leading-relaxed md:leading-snug text-center ${isChinese ? 'text-3xl md:text-5xl' : 'text-4xl md:text-5xl'}`}>
                                {t('platform_ceo_title')}
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default BrainCog_Born